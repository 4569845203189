import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { YouTubePlayer } from '@angular/youtube-player';
import {
  faFacebookSquare,
  faInstagramSquare,
  faLinkedin,
  faYoutubeSquare,
} from '@fortawesome/free-brands-svg-icons';
import { NguCarousel, NguCarouselConfig } from '@ngu/carousel';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements AfterViewInit {
  @ViewChild('videoCarousel') videoCarousel: NguCarousel<any>;
  @ViewChildren(YouTubePlayer) ytPlayers: QueryList<YouTubePlayer>;

  faYoutubeSquare = faYoutubeSquare;
  faFacebookSquare = faFacebookSquare;
  faInstagramSquare = faInstagramSquare;
  faLinkedin = faLinkedin;

  carouselConfig: NguCarouselConfig = {
    grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
    slide: 1,
    speed: 250,
    point: { visible: true },
    load: 5,
    velocity: 0,
    touch: true,
    easing: 'cubic-bezier(0, 0, 0.2, 1)',
  };

  videoCarouselItems = [
    {
      videoId: 'oha_gqCTAGs',
      caption:
        'Brass-heavy epic orchestra + choir, with stuttering synths + FX',
    },
    {
      videoId: 'nn97904K5Mo',
      caption:
        'Music for the discovery of a new universe (competition entry): mystery, drama, & possibility',
    },
    {
      videoId: 'z2qkTSqSdyE',
      caption: 'Quick world/folk style music in 7/8 time (Slendro tuning)',
    },
    {
      videoId: 'qdUPD6a9R-w',
      caption: 'New podcast music for Programming Throwdown!',
    },
    {
      videoId: 'HCK4GpKIny4',
      caption:
        'Orchestral + guitar action music (Westworld scene, scored for a competition)',
    },
  ];

  photoCarouselItems = [
    { src: 'assets/eric.png', alt: 'Eric sitting in music studio' },
    {
      src: 'assets/liveend.png',
      alt: 'Recording studio including microphones, a trombone, and various sheet music',
    },
    {
      src: 'assets/working.png',
      alt: 'Eric working on his music studio computer',
    },
    { src: 'assets/reclight.png', alt: 'Red RECORDING light' },
    {
      src: 'assets/dogs.png',
      alt: "Eric's chihuahuas sitting beside him in the music studio",
    },
  ];

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  prevVideo(): void {
    this.videoCarousel.moveTo(this.videoCarousel.currentSlide - 1);
  }

  nextVideo(): void {
    this.videoCarousel.moveTo(this.videoCarousel.currentSlide + 1);
  }

  handleVideoCarouselMove(): void {
    this.ytPlayers.forEach((player) => {
      player.pauseVideo();
    });
  }
}
