<div><a href="">&lt; Back to home</a></div>

<h2>Virtual Orchestra Guides, Templates, &amp; Presets</h2>

<p>Way more coming here very shortly!</p>

<mat-nav-list>
  <a href="https://gcs.barndollarmusic.com/HwPrcDrumMaps_2022-03-31.zip">
    <mat-list-item>
      <mat-icon mat-list-icon>audio_file</mat-icon>
      East West Hollywood Orchestral Percussion + Harp Drum Maps (Cubase)
    </mat-list-item>
  </a>
</mat-nav-list>
