<div class="example-music">
  <iframe
    width="100%"
    height="500"
    scrolling="no"
    frameborder="no"
    src="https://play.reelcrafter.com/embed/3473aa67-0eaf-46f5-a828-2316033cee3f"
  >
  </iframe>
</div>

<!-- <ngu-carousel
  #videoCarousel
  [inputs]="carouselConfig"
  [dataSource]="videoCarouselItems"
  class="carousel video-carousel"
  (onMove)="handleVideoCarouselMove()"
>
  <div *nguCarouselDef="let item" class="item">
    <div class="carousel-tile">
      <div class="video">
        <youtube-player [videoId]="item.videoId"></youtube-player>
      </div>

      <mat-card class="video-card">
        <mat-card-content>
          <div class="video-caption">{{ item.caption }}</div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</ngu-carousel> -->

<!-- <div class="carousel-bottom-controls">
  <button
    mat-mini-fab
    [disabled]="videoCarousel.activePoint === 0"
    (click)="prevVideo()"
    class="carousel-prev"
  >
    <mat-icon>navigate_before</mat-icon>
  </button>
  <ul class="carousel-points" NguCarouselPoint>
    <li
      *ngFor="let i of videoCarousel.pointNumbers"
      [class.active]="videoCarousel.activePoint === i"
      (click)="videoCarousel.moveTo(i)"
    >
      &bull;
    </li>
  </ul>
  <button
    mat-mini-fab
    [disabled]="videoCarousel.activePoint === videoCarouselItems.length - 1"
    (click)="nextVideo()"
    class="carousel-next"
  >
    <mat-icon>navigate_next</mat-icon>
  </button>
</div> -->

<p>Need original music or looking for help on a music production project?</p>
<div class="cta">
  <a
    href="mailto:inquiries@barndollarmusic.com"
    mat-raised-button
    color="accent"
    ><mat-icon>email</mat-icon> Get in touch</a
  >
</div>
<div class="cta">
  <a
    href="https://www.youtube.com/channel/UCQKlNSUfZW-zSjF8ej9E8tQ"
    class="social-btn"
    ><fa-icon [icon]="faYoutubeSquare"></fa-icon
  ></a>
  <a href="https://www.facebook.com/BarndollarMusic" class="social-btn"
    ><fa-icon [icon]="faFacebookSquare"></fa-icon
  ></a>
  <a href="https://www.instagram.com/barndollarmusic/" class="social-btn"
    ><fa-icon [icon]="faInstagramSquare"></fa-icon
  ></a>
  <a href="https://www.linkedin.com/in/ericbarndollar/" class="social-btn"
    ><fa-icon [icon]="faLinkedin"></fa-icon
  ></a>
</div>
<div class="cta">
  <mat-nav-list>
    <a href="resources">
      <mat-list-item>
        <mat-icon mat-list-icon>piano</mat-icon>
        Resources for Musicians
      </mat-list-item>
    </a>
    <a href="vorch">
      <mat-list-item>
        <mat-icon mat-list-icon>audio_file</mat-icon>
        Virtual Orchestra Guides, Templates, Presets
      </mat-list-item>
    </a>
  </mat-nav-list>
</div>

<ngu-carousel
  #carousel
  [inputs]="carouselConfig"
  [dataSource]="photoCarouselItems"
  class="carousel photo-carousel"
>
  <div *nguCarouselDef="let item" class="item">
    <img class="carousel-tile" [src]="item.src" [alt]="item.alt" />
  </div>

  <button
    mat-mini-fab
    [disabled]="carousel.activePoint === 0"
    NguCarouselPrev
    class="carousel-prev"
  >
    <mat-icon>navigate_before</mat-icon>
  </button>
  <button
    mat-mini-fab
    [disabled]="carousel.activePoint === photoCarouselItems.length - 1"
    NguCarouselNext
    class="carousel-next"
  >
    <mat-icon>navigate_next</mat-icon>
  </button>

  <ul class="carousel-points" NguCarouselPoint>
    <li
      *ngFor="let i of carousel.pointNumbers"
      [class.active]="carousel.activePoint === i"
      (click)="carousel.moveTo(i)"
    >
      &bull;
    </li>
  </ul>
</ngu-carousel>

<div class="photo-credit">photos: Emily Stokes</div>
