import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  ngOnInit() {
    // This code loads the IFrame Player API code asynchronously, according to the instructions at
    // https://developers.google.com/youtube/iframe_api_reference#Getting_Started.
    const scriptEl = document.createElement('script');
    scriptEl.src = 'https://www.youtube.com/iframe_api';
    document.body.appendChild(scriptEl);
  }
}
