<div class="page">
  <header>
    <div class="wrapper">
      <h1 class="name">Eric Barndollar</h1>
      <p class="tagline">
        I write music for film, TV, game, &amp; podcast soundtracks.
      </p>
    </div>
  </header>

  <main>
    <div class="wrapper">
      <router-outlet></router-outlet>
    </div>
  </main>

  <footer>
    <span class="copyright">Copyright &copy; 2025</span>
    <img
      class="logo"
      src="assets/bmlogo_full_white.svg"
      alt="Barndollar Music logo, with two music notes and a film strip"
    />
    <span class="company-name">Barndollar Music, Ltd.</span>
  </footer>
</div>
