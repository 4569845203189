<div><a href="">&lt; Back to home</a></div>

<h2>Resources for Musicians</h2>

<div class="resource">
  <div class="resource-title">Virtual Orchestra</div>
  <ul>
    <li><a href="vorch">Guides, Templates, &amp; Presets</a></li>
  </ul>
</div>

<div class="resource">
  <div class="resource-title">Music Log + Cue Sheet Generator</div>
  <div class="resource-desc">Spreadsheet &amp; custom timecode functions for tracking film &amp; TV cues</div>

  <ul>
    <li><a href="https://github.com/barndollarmusic/gsheets-timecode">Google Sheets (gsheets-timecode)</a></li>
    <li><a href="https://github.com/barndollarmusic/excel-timecode">Excel (excel-timecode)</a></li>
  </ul>
</div>

<div class="resource">
  <div class="resource-title">wavcheck</div>
  <div class="resource-desc">Check WAV files for BWF/timecode issues, auto add _TC01020304.wav to filenames</div>

  <ul>
    <li><a href="https://github.com/barndollarmusic/wavcheck">wavcheck Script (GitHub)</a></li>
  </ul>
</div>

<div class="resource">
  <div class="resource-title">mapdrums</div>
  <div class="resource-desc">Generate Cubase Drum Map (.drm) files from input CSV</div>

  <ul>
    <li><a href="https://github.com/barndollarmusic/mapdrums">mapdrums Script (GitHub)</a></li>
  </ul>
</div>

<div class="resource">
  <div class="resource-title">Cubase Pro 11: Shortcuts Cheat Sheet</div>
  <div class="resource-desc">Reference documentation for keyboard &amp; mouse shortcuts.</div>

  <img class="shortcuts-preview"
       src="assets/cubase-shortcuts-preview.png"
       alt="Preview of document contents">

  <div>Download PDFs:</div>
  <ul>
    <li><a href="https://github.com/barndollarmusic/cubase-resources/raw/main/shortcuts/CubaseShortcutsWin.pdf">Windows</a></li>
    <li><a href="https://github.com/barndollarmusic/cubase-resources/raw/main/shortcuts/CubaseShortcutsMac.pdf">macOS</a></li>
    <li><a href="https://github.com/barndollarmusic/cubase-resources/raw/main/shortcuts/CubaseShortcutsWinStarless.pdf">Windows (without stars)</a></li>
    <li><a href="https://github.com/barndollarmusic/cubase-resources/raw/main/shortcuts/CubaseShortcutsMacStarless.pdf">macOS (without stars)</a></li>
  </ul>

  <div>Features:</div>
  <ul>
    <li>Clear organization (<i>e.g.</i> by Writing &amp; Recording, Editing, Mixing &amp; Mastering).</li>
    <li>My so-bad-they're-good mnemonics (<i>e.g.</i> "<u>K</u>omrades" for Group Editing).</li>
    <li>Includes mouse and contextual shortcuts not in the Key Commands list.</li>
    <li>Starred versions highlight most useful &amp; time saving shortcuts.</li>
  </ul>

  <p>For the source Word documents, see the
    <a href="https://github.com/barndollarmusic/cubase-resources/tree/main/shortcuts">project on GitHub</a>.</p>
</div>
